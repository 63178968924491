import $ from 'jquery';
import Cookies from 'js-cookie';

export default class FontSize {

    init() {
        // $(window).on('load', () => {
        //     this.fontSizeChange();
        // })

        let cookies = Cookies.get('fontSize');
        console.log('fontSize');

        let elm = $('html');
        //変数が空ならfontSを、空でなければクッキーに保存しておいたものを適用

        if (!Cookies.get('fontSize')) {
            elm.addClass('fzS');
            $('#fzS').addClass('is-current');
        } else { 
            elm.addClass(cookies);
            $('#fzS').removeClass('is-current');
            $('#' + cookies).addClass('is-current');
        }

        //ボタンをクリックしたら実行
        $('.js-fontSize').click(function () {
            //is-currentでないボタンだった場合のみ動作
            if (!$(this).hasClass('is-current')) {
                //現在is-currentのついているclassを削除
                $('.is-current').removeClass('is-current');
                //クリックしたボタンをis-current
                $(this).addClass('is-current');
                //クリックした要素のID名を変数にセット
                let setFontSize = this.id;
                //クッキーに変数を保存
                Cookies.set('fontSize', setFontSize,{expires: 30});
                //一度classを除去して、変数をclassとして追加
                elm.removeClass().addClass(setFontSize);
            }
        });
    }


}