
/**
 * コモンクラス
 * @constructor
 */
class Common {
    constructor() {
        this.speed = 800;
        this.stay = $(window).scrollTop();
        this.wh = $(window).height();
    }
    /**
     * コモン
     */
    init() {
        const self = this;

        $('a[href^="#"]').on('click', function () {
            self.pageScroll(this);
        });

        $(window).on('load scroll', () => {
            this.stayPosition = $(window).scrollTop();
            this.scrollHeight = $(document).height();
            // this.fontSize();
            this.toTopPosition();
            // this.addListeners()
        });


        $('.l-footer__toTop').on('click', () => {
            this.toTop();
        });

    }
    
    pageScroll(el) {
        let self, href, target, position;
        href = $(el).attr("href");
        target = $(href == "#" || href == "" ? 'html' : href);
        position = target.offset().top;
        $("html, body").animate({ scrollTop: position }, this.speed);
    }


    toTopPosition() { 
        let topBtn = $('.l-footer__toTop');

        if(this.stayPosition > 100){
            topBtn.addClass('is-show').fadeIn();
        }else{
            topBtn.removeClass('is-show').fadeOut();
        }

        let scrollPosition = $(window).height() + this.stayPosition;
        let footHight = $('.l-footer').innerHeight();
        let w = $(window).width();
        let x = 765;

        if (this.scrollHeight - scrollPosition <= footHight) { 
            topBtn.css({
                "position": "absolute",
                "bottom": footHight - 34,
              });
        } else {
            topBtn.css({
                "position": "fixed",
                "bottom":"20px",
            });
            if (w <= x) { 
                topBtn.css({
                    "position": "fixed",
                    "bottom":"120px",
                });
            }
        };
    }

    toTop() { 
        $('body,html').animate({
            scrollTop: 0
        }, 800);
        return false;
    }
}

export default Common;
