export default class SpMenu {

    constructor() {
        this.$navButton = $('.js-spBtn');
        this.$navWrap = $('.js-spWrap');
        this.$spHeader = $(".l-header");
        this.ww = window.innerWidth;
    }

    init() {
        this.$navButton.on('click', (e) => {
            this.toggleSpMenu(e.currentTarget);
        }); 
        $('.js-nav').on('click', (e) => {
            this.closeSpMenu(e.currentTarget);
        });
        $(window).on('resize load', () => { 
            this.resetPcView()
        })


    }

    toggleSpMenu(el) { 
        ($(el).hasClass('is-active')) ? this.closeSpMenu(el) : this.openSpMenu(el);
    }

    openSpMenu(el) { 
        let $cover = document.getElementById('overlay');

        $(el).addClass('is-active');
        this.$navWrap.fadeIn(500).addClass('is-open');
        document.body.classList.toggle('open');
        $cover.style.display = "block";
    }

    closeSpMenu(el) { 
        let $cover = document.getElementById('overlay');

        $(el).removeClass('is-active');
        this.$navWrap.fadeOut().removeClass('is-open');
        document.body.classList.remove('open');
        $cover.style.display = "none";
    }

    resetPcView() {
        this.ww = window.innerWidth;
        if (this.ww > 768) {
            this.$navWrap.removeAttr('style'); 
        }
    }
}