/**
 * サンプルクラス
 * @constructor
 */
import Swiper from 'swiper';
class Comic {
  constructor() {
    this.ww = window.innerWidth;
    this.hh = window.innerHeight;
  }
  /**
   * サンプル
   */
  init() {
    this.slideInit()
    $(window).on('resize load',()=>{
      this.ww = window.innerWidth;
      this.hh = window.innerHeight;
      if(this.ww <= 750){
        if(this.ww < this.hh ){
          $('.m-comicPBModal').removeClass('isWide')
          $('.m-comicPBModal').addClass('isHeight')
        }else{
          $('.m-comicPBModal').removeClass('isHeight')
          $('.m-comicPBModal').addClass('isWide')
        }
        $('.m-comicPicList__item').on('click',()=>{
          $('.m-comicPBModal').addClass('isShow');
        })

      }
    })
    $('.m-comicPBModal__lay,.m-comicPBModal__close').on('click',()=>{
      $('.m-comicPBModal').removeClass('isShow');
    });
  }
  slideInit() {
    var swiper = new Swiper('.js-comicSlide.swiper-container', {
      loop: false, //最後に達したら先頭に戻る
      slidesPerView: 1,
      initialSlide:$('.m-comicSlideList').find('li').length,
      slidesPerGroup:1,
      navigation: {  //ナビゲーションのオプション（矢印ボタンの要素を指定）
        nextEl: '.button-next',
        prevEl: '.button-prev',
      },
      breakpoints: {
        751: {
          slidesPerView: 2,
          slidesPerGroup:2,
        }
      },
    });
    var swiperPB =new Swiper('.js-comicPBSlide.swiper-container', {
      loop: false, //最後に達したら先頭に戻る
      slidesPerView: 1,
      initialSlide:$('.m-comicSlideList').find('li').length,
      slidesPerGroup:1,
      navigation: {  //ナビゲーションのオプション（矢印ボタンの要素を指定）
        nextEl: '.button-next',
        prevEl: '.button-prev',
      }
    });
  }
};


export default Comic;
